<template>
  <v-container>
    <v-row>
      <v-col cols="12" class="text-center">
        <v-card flat>
          <v-card-title class="headline">
            {{ $t("nav.help") }} - {{ $t("nav.first-aid").toLowerCase() }}
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>
    <v-expansion-panels>
      <template v-for="(url, title) in videos">
        <help-video-frame
          :key="title"
          :videoTitle="title"
          :videoSource="url"
        ></help-video-frame>
      </template>
    </v-expansion-panels>
  </v-container>
</template>

<script>
import HelpVideoFrame from "@/views/helpvideos/HelpVideoFrame.vue";
export default {
  components: {
    HelpVideoFrame,
  },
  data() {
    return {
      videos: {
        // "1. Insults | инсульт": "https://www.youtube.com/embed/BKl7Zyum5yY",
        // "2. Infarkts | сердечный приступ":
        //   "https://www.youtube.com/embed/IYYyDzZrPfg",
        // "3. Lauzta roka vai kāja | сломанная рука или нога":
        //   "https://www.youtube.com/embed/975JKZhUE3o",
        // "4. Aizrīšanās | удушье": "https://www.youtube.com/embed/VUb7R7rmvDA",
        // "5. Kukaiņu dzēlieni | укусы насекомых":
        //   "https://www.youtube.com/embed/r3ZVtv6R1MM",
        // "6. Apdegumi, applaucēšanās | ожоги ":
        //   "https://www.youtube.com/embed/iqo4uZIibns",
        // "7. Pārkaršana | перегрев ":
        //   "https://www.youtube.com/embed/FdDGpmTZhmQ",
        // "8. Asiņošana | кровотечение ":
        //   "https://www.youtube.com/embed/nKvV1nDdSD4",
        // "9. Pareiza sānu poza | правильная боковая осанка  ":
        //   "https://www.youtube.com/embed/G6ww1-13xqs",
        "video-tutorials.first-aid.video-1":
          "https://www.youtube.com/embed/BKl7Zyum5yY",
        "video-tutorials.first-aid.video-2":
          "https://www.youtube.com/embed/IYYyDzZrPfg",
        "video-tutorials.first-aid.video-3":
          "https://www.youtube.com/embed/975JKZhUE3o",
        "video-tutorials.first-aid.video-4":
          "https://www.youtube.com/embed/VUb7R7rmvDA",
        "video-tutorials.first-aid.video-5":
          "https://www.youtube.com/embed/r3ZVtv6R1MM",
        "video-tutorials.first-aid.video-6":
          "https://www.youtube.com/embed/iqo4uZIibns",
        "video-tutorials.first-aid.video-7":
          "https://www.youtube.com/embed/FdDGpmTZhmQ",
        "video-tutorials.first-aid.video-8":
          "https://www.youtube.com/embed/nKvV1nDdSD4",
        "video-tutorials.first-aid.video-9":
          "https://www.youtube.com/embed/G6ww1-13xqs",
      },
    };
  },
};
</script>
